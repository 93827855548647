import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Trans, withI18n } from '@lingui/react';
import NavPanel from './NavPanel';
import { Link } from 'gatsby';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import KickStarterBanner from '../components/KickStarterBanner';
import moment from 'moment';
import { map } from 'lodash';
import classNames from 'classnames';
import Dropdown from './Dropdown';

// Images
import AdaLogo from './img/AdaLogo';
import FacebookIcon from './img/FacebookIcon';
import InstagramIcon from './img/InstagramIcon';
import TwitterIcon from './img/TwitterIcon';
import YoutubeIcon from './img/YoutubeIcon';
import AppleIcon from './img/AppleIcon';
import ThreadIcon from './img/TiktokIcon';
import TiktokIcon from './img/ThreadIcon';
import SpotifyIcon from './img/SpotifyIcon';

// styles
import '../styles/style.scss';

class TemplateWrapper extends Component {
  constructor(props) {

    super(props);
    this._$refTemplateWrapper = null;
    this.state = {
      $refTemplateWrapper: null,
      decoratorCls: '',
      showOverlay: false,
      onDevelopment: null,
      funcDecorator: (cls) => {
        this.setState({
          decoratorCls: cls,
        });
      },
    };

    this.languageOptions = {
      en: 'ENGLISH (CA)',
      fr: 'FRENCH (CA)'
    }
  }
  
  componentDidMount() {
    const { location, pathName } = this.props;
    const onDevelopment = location.href.indexOf('staging') > -1 || location.href.indexOf('localhost') > -1;

    this.setState({
      onDevelopment: onDevelopment,
      $refTemplateWrapper: this._$refTemplateWrapper,
      showOverlay: !getCookieConsentValue('ac_session'),
    });
  }

  onAcceptConsent() {
    this.setState({
      showOverlay: !getCookieConsentValue('ac_session')
    });
  }

  renderLanguageSwitcher() {
    const { lang, languages, location } = this.props;

    const getUrlPath = (language) => {
      let path = '';

      if (language === lang) {
        path = location.pathname;
      } else if (language === 'en') {
        path = location.pathname.substr(3);
      } else {
        path = `/${language}${location.pathname}`;
      }

      return path;
    }

    return <Dropdown className="language-select" title={`Language:  ${this.languageOptions[lang]}`}>
      {map(languages, (language, i) => {
        return <Link
          key={i}
          to={getUrlPath(language)}
        >
          {this.languageOptions[language]}
        </Link>
      })}
    </Dropdown>
  }

  renderMainFooter() {
    const { location, pathName } = this.props;
    const onMainPage = location.pathname === '/' || location.pathname === '/fr/';
    const on404Page = pathName.includes('/404/');

    return (
      <footer className={classNames(`main-footer ${onMainPage ? 'on-main-page' : ''} ${on404Page ? 'on-404-page' : ''}`)}>
        <div className="wrapper container">
          <AdaLogo />
          <div className="social-links">
            <a className="social-links__item _spotify" href="https://open.spotify.com/artist/2rCT4lUvXXRyeD4dlb5dL1?si=W_rplEWISxaUoalJacoRMw" target="_blank">
              <SpotifyIcon />
            </a>
            <a className="social-links__item _tiktok" href="https://www.tiktok.com/@adachan.music" target="_blank">
              <TiktokIcon />
            </a>
            <a className="social-links__item _thread" href="https://www.threads.net/@ada.tunes" target="_blank">
              <ThreadIcon />
            </a>
            <a className="social-links__item _apple" href="https://music.apple.com/artist/ada-chan/1652987859" target="_blank">
              <AppleIcon />
            </a>
            <a className="social-links__item _youtube" href="https://www.youtube.com/@AdaTunes" target="_blank">
              <YoutubeIcon />
            </a>
            <a className="social-links__item _instagram" href="https://www.instagram.com/ada.tunes" target="_blank">
              <InstagramIcon />
            </a>
            <a className="social-links__item _twitter" href="https://x.com/@adatunes" target="_blank">
              <TwitterIcon />
            </a>
          </div>
          <div>ALL RIGHTS RESERVED. COPYRIGHT &copy; {moment().format('Y')}. ADACHAN.COM. DESIGNS BY INTERSTELLAR STUDIO. PUBLISHED BY LUMEN CAFE</div>
        </div>
      </footer>
    );
  }

  render() {
    const { pathName, children, location } = this.props;
    const isLabPage = pathName.includes('lab') || pathName.includes('process');
    const lightCls = isLabPage ? 'page-is-light' : '';
    
    const {
      $refTemplateWrapper, funcDecorator, decoratorCls, showOverlay
    } = this.state;

    const withOverlayCls = showOverlay ? 'with-overlay' : '';

    return (
      <div
        ref={(_mount) => {
          this._$refTemplateWrapper = _mount;
        }}
        className={`main-wrapper ${lightCls} ${decoratorCls} ${withOverlayCls}`}
        >
        <TemplateWrapperContext.Provider
          value={{ $refTemplateWrapper, funcDecorator }}
        >
          <Helmet defaultTitle="Ada Chan" titleTemplate="%s - Announcement soon">
            <meta name="og:type" content="website" />
            <meta name="og:site_name" content="Ada Chan" />
            <title>Ada Chan</title>
            <meta name="description" content="" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          </Helmet>
          
          <NavPanel
            {...this.props}
          />
          <main className="main-content">
            { children }
          </main>
          {this.renderMainFooter()}
          <CookieConsent
            containerClasses="cookie-consent"
            location="bottom"
            buttonText="Accept"
            cookieName="ac_session"
            disableStyles={true}
            disableButtonStyles={true}
            buttonClasses="button button--black"
            expires={9999}
            onAccept={() => this.onAcceptConsent()}
          >
            <div>
              This website uses cookies.<br />
              By using this website you consent to our use of these cookies.<br /><br />
              <span className="">For more information visit our { ' ' }
                <Link to="/privacy-policy">Privacy Policy</Link>.</span>
            </div>
          </CookieConsent>
        </TemplateWrapperContext.Provider>
      </div>
    );
  }
}

export default withI18n()(TemplateWrapper);
export const TemplateWrapperContext = React.createContext();
